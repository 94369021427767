#pending {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 150px);

  p {
    font-size: clamp(1rem, -0.875rem + 8.333vw, 3.5rem);
    text-align: center;
    line-height: 1.3;
  }
}
