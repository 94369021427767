@import '../../scss/_global.scss';

h2 {
  font-family: $font-family-medium;
}

.bold {
  font-family: $font-family-medium;
}

@media print {
  .print-info {
    display: block;
    padding: 0 100px;
    width: 700px;
  }
}
