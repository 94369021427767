$primary-color: #ffffff;
$secondary-color: #000000;
$tertiary-color: #0000ff;
$background-color: rgb(255, 255, 255);
$error-color: rgb(237, 10, 10);
$error-background-color: rgb(254, 194, 194);

$padding-lxl: 40px;
$padding-ml: 25px;
$padding-sm: 20px;

$border-radius: 0;
$border: 1px solid rgba(0, 0, 0, 0.04);
$shadow: rgba(0, 0, 0, 0.05) 0px 0px 8px 0px;

$button-color: #000000;
$button-text-color: #ffffff;
$button-hover-color: rgb(255, 255, 255);
$button-hover-text-color: #000000;
$button-border-color: rgba(0, 0, 0, 1);
$button-padding: 16px 50px;
$button-border-radius: 50px;
$button-shadow: unset;

$button-chart-padding: 11px 27px;

$font-family: 'DIN_regular', sans-serif;
$font-family-bold: 'DIN_bold', sans-serif;
$font-family-light: 'DIN_light', sans-serif;
$font-family-medium: 'DIN_medium', sans-serif;
$font-size: 16px;
$font-size-error: 12px;
$font-size-code: 20px;
$font-weight: 100 !important;
$font-size-h1: 56px !important;
$font-size-mobile-h1: 40px !important;
$font-line-height: normal !important;

$close-button-size: 30px;

$login-code-background-color: rgba(243, 243, 243, 1);
$login-select-background-color: rgba(243, 243, 243, 1);

$chart-info-background-color: rgba(255, 255, 255, 1);
$chart-info-close-button-color: rgba(0, 0, 0, 1);

$survey-question-icon-1: url('../assets/images/real_icon.svg');
$survey-question-icon-2: url('../assets/images/wunsch_icon.svg');

$survey-checkbox-size: 30px;
$survey-checkbox-inner-size: 14px;
$survey-checkbox-size-mobile: 20px;
$survey-checkbox-inner-size-mobile: 8px;

$survey-fixed-table-background: rgba(0, 0, 255, 1);
$survey-fixed-table-color: rgba(255, 255, 255, 1);

:root {
  --font-family: 'DIN_regular', sans-serif;
  --font-family-bold: 'DIN_bold', sans-serif;
  --font-family-light: 'DIN_light', sans-serif;
  --font-weight: 100;
  --primary-color: #ffffff;
  --secondary-color: #000000;
  --tertiary-color: #0000ff;
  --error-background-color: rgb(186, 186, 186);
  --error-color: rgb(87, 87, 87);

  --chart-color-1: 0, 0, 0;
  --chart-color-2: 0, 0, 255;
  --chart-color-3: 255, 255, 153;
  --chart-color-4: 144, 238, 144;
  --chart-color-5: 220, 208, 255;
  --chart-color-6: 255, 204, 153;
  --chart-color-7: 173, 216, 230;
  --chart-color-8: 192, 192, 192;
  --chart-color-9: 255, 218, 185;
  --chart-color-10: 189, 252, 201;
}
