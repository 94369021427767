@import '../../scss/_global.scss';

#chart-header {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin-bottom: 30px;

  h1 {
    margin-bottom: 0 !important;
    font-size: 30px !important;

    @media screen and (min-width: 600px) {
      margin-bottom: 40px;
    }
  }

  .subtitle {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;

    line-height: 22px;

    @media screen and (min-width: 600px) {
      padding-left: 20px;
      padding-right: 20px;
      width: 80%;
    }
  }

  .legend-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 14px;

    @media screen and (min-width: 600px) {
      width: 100%;
    }

    .legends {
      display: flex;
      justify-content: center;
      width: 100%;

      @media screen and (min-width: 600px) {
        justify-content: space-around;
        width: 300px;
      }

      .legend {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        .icon {
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          align-items: center;
          background-color: #000000;
          padding: 0px;
          margin-right: 10px;

          img {
            width: 40px;
          }
        }

        &.second {
          margin-left: 20px;
          @media screen and (min-width: 600px) {
            margin-left: 0;
          }

          .icon {
            border-radius: 50px;
            background-color: #0000ff;
          }
        }
      }
    }
    .button-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 23px;

      @media screen and (min-width: 500px) {
        flex-direction: row;
      }

      @media screen and (min-width: 810px) {
        margin-top: 0;
      }

      button {
        width: fit-content;
        border: 1px solid $button-border-color;
        border-radius: $button-border-radius;
        padding: $button-chart-padding;
        margin-left: 10px;
        margin-right: 10px;

        font-size: $font-size;
        background-color: $button-color;
        color: $button-text-color;
        box-shadow: $button-shadow;
        transition: background-color 0.3s, color 0.3s, border 0.3s;
        cursor: pointer;

        &:nth-child(2) {
          margin-top: 10px;
        }

        @media screen and (min-width: 500px) {
          &:nth-child(2) {
            margin-top: 0;
          }
        }

        &.highlighted {
          color: $primary-color;
          background-color: $secondary-color;
        }

        &:hover {
          background-color: $button-hover-color;
          color: $button-hover-text-color;
        }

        &:disabled,
        &[disabled] {
          border: 1px solid #999999;
          background-color: #cccccc;
          color: #666666;
          cursor: auto;
        }
      }
    }
  }

  @media print {
    display: block;
    h1,
    p {
      width: 100%;
      text-align: start;
      padding-left: 0;
    }

    .subtitle {
      width: 100%;
      text-align: start;
      padding-left: 0;
      margin-bottom: 40px;
      width: 700px;
    }

    .legend-container {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-around;

      .button-container {
        display: none;
      }

      .legends {
        width: 300px;
        .legend {
          flex: 1;
          display: flex;
          justify-content: row wrap;
          justify-content: center;

          .icon {
            width: 40px;
            height: 40px;
            float: left;
          }

          p {
            width: 80px;
            //margin: -30px 0 0 -50px;
            float: left;
          }
        }
      }
    }
  }
}
