@import '../../scss/_global.scss';
.sv-progressbar-percentage {
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
  line-height: 32px;
  display: none;

  

  &.visible {
    display: block;
  }
}

.sv-progressbar-percentage__title {
  font-size: $font-size;
  display: flex;
}

.sv-progressbar-percentage__bar-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.sv-progressbar-percentage__indicator {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 2px;
  border-bottom: 1px solid #000000;
}

.sv-progressbar-percentage__value-bar {
  position: absolute;
  background-color: #000000;
  left: 0;
  top: 0;
  height: 100%;
}

.sv-progressbar-percentage__value {
  font-size: $font-size;
  display: flex;
  color: #000000;
}
