.finish {
  display: flex;
  justify-content: flex-start;
  text-align: start;
  flex-direction: column;
  padding: 40px;

  h1 {
    margin-bottom: 0;
  }
}
