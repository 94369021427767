@import '../../scss/global.scss';

#fixedTable {
  display: none;
  position: fixed;
  top: -100px;
  left: 0;
  width: calc(100% - (4 * $padding-sm) + 0px);
  padding-left: calc((2 * $padding-sm) + 0px);
  padding-right: calc((2 * $padding-sm) + 0px);
  z-index: 1000;
  transition: top 0.6s;

  @media screen and (min-width: 1060px) {
    width: calc(100% - (2 * $padding-lxl) + 0px);
    padding-left: 40px;
    padding-right: 40px;
  }

  &.show {
    top: 0;
  }

  &.visible {
    @media screen and (min-width: 600px) {
      display: block;
    }
  }

  .content {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .table-content {
      width: 100%;
      max-width: 1120px;
      background-color: $survey-fixed-table-background;
      color: $survey-fixed-table-color;
      padding: 20px 40px;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);

      table {
        //padding: 30px;
        width: 100%;
        max-width: 1120px;

        thead tr > th {
          width: 120px;
          min-width: 80px;
        }

        thead tr > td:first-child {
          width: 31%;
          min-width: 80px;
          text-align: left;
          font-size: 16px;

          @media screen and (min-width: 1060px) {
            font-size: 22px;
          }
        }
      }
    }
  }
}
