@import '../../scss/_global.scss';

.chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;

  @media screen and (min-width: 600px) {
    margin-top: 0;
  }

  .printable-content {
    display: block;
    width: 100%;

    .print-logo {
      display: none;
    }

    .print-info {
      display: none;
    }

    .canvas-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: fit-content;
      width: 100%;
    }
  }

  .info-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;

    &.visible {
      visibility: visible;
      opacity: 1;
    }

    .info-background {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100vh;
    }

    .info-content {
      position: relative;
      width: auto;
      max-width: 600px;
      height: auto;
      max-height: 528px;
      background-color: $chart-info-background-color;
      border-radius: 20px;
      padding: 40px 40px 60px 40px;
      margin-left: 20px;
      margin-right: 20px;
      line-height: 22px;
      z-index: 1;
      overflow-y: scroll;

      @media screen and (min-width: 600px) {
        max-height: 680px;
      }

      .close {
        background: none;
        border: none;
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;

        span {
          font-size: $close-button-size;
          color: $chart-info-close-button-color;
        }
      }
    }
  }
}

@media print {
  .print-logo {
    display: block;
    width: 240px;
    margin-bottom: 80px;
    padding: 40px;
    position: absolute;
    right: 0;
    top: 0;

    img {
      width: 240px;
    }
  }

  .printable-content {
    margin-top: 140px;
    width: 100vw;

    .canvas-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: fit-content;
      width: 700px;
      padding-left: 104px;

      canvas {
        width: 700px !important;
        height: auto !important;
        margin-bottom: 30px;
      }
    }

    h1,
    p {
      width: 100%;
      text-align: left;
      padding-left: 100px;
    }

    .subtitle {
      width: 100%;
      text-align: left;
      padding-left: 100px;
      margin-bottom: 40px;
      width: 700px;
    }
  }

  .print-info {
    display: block;
    padding: 0 100px;
    width: 700px;
  }
}
