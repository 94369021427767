@import './scss/global.scss';

@font-face {
  font-family: 'DIN_bold';
  src: url('assets/fonts/692234/121784c1-863c-4e49-b682-625ea7de55b0.woff2') format('woff2'), url('assets/fonts/692234/9949035f-6226-4080-a14a-bf7d94ffb1e1.woff') format('woff');
}

@font-face {
  font-family: 'DIN_medium';
  src: url('assets/fonts/5563051/afabdfcf-432c-49b4-a496-0e5051e87fa9.woff2') format('woff2'), url('assets/fonts/5563051/611ea865-db7e-4c05-94b8-bd1675c90576.woff') format('woff');
}

@font-face {
  font-family: 'DIN-light';
  src: url('assets/fonts/5563268/14aba99e-8c97-4daa-a8df-67920a051402.woff2') format('woff2'), url('assets/fonts/5563268/96fd6d6f-fad6-4dd9-ab70-29547a56cacd.woff') format('woff');
}

@font-face {
  font-family: 'DIN_regular';
  src: url('assets/fonts/5563279/6b4d4830-9c7d-43d2-b5d3-c73f739561b9.woff2') format('woff2'), url('assets/fonts/5563279/ccf25ada-6d2a-4133-95dc-deb039e22999.woff') format('woff');
}

@page {
  size: auto;
  margin: 0mm;
}

body {
  background-color: $background-color;
  margin: 0;
}

body,
button,
input,
select,
p,
span,
h1,
h2,
h3 {
  font-family: $font-family;
  font-weight: 400 !important;
  -webkit-font-smoothing: antialiased !important;
}

h1 {
  font-family: $font-family;
  font-size: $font-size-mobile-h1;
  line-height: $font-line-height;
  margin: 0;

  @media screen and (min-width: 600px) {
    font-size: $font-size-h1;
  }
}

#root {
  margin: 0;
  padding: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}