.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  img {
    height: 20px;

    @media screen and (min-width: 600px) {
      height: 40px;
    }

    &.brand {
      height: 60px;

      @media screen and (min-width: 600px) {
        height: 100px;
      }

      &.hide {
        visibility: hidden;
      }
    }
  }
}