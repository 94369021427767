@import './scss/global.scss';

.App {
  text-align: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  padding-left: $padding-sm;
  padding-right: $padding-sm;

  @media screen and (min-width: 600px) {
    padding-left: $padding-ml;
    padding-right: $padding-ml;
  }

  @media screen and (min-width: 640px) {
    padding-left: $padding-lxl;
    padding-right: $padding-lxl;
  }

}

.show-chart-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .show-chart-button {
    background-color: #1ab394;
    color: #fff;
    border: none;
    padding: 18px 20px;
  }
}