@import '../../scss/global.scss';

.sd-root-modern {
  background-color: $background-color !important;
}

.sv_row {
  border: none !important;
}

.sd-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.sd-action-bar {
  &.hide {
    display: none;
  }
}

.sd-btn {
  font-weight: $font-weight;
  border-radius: $button-border-radius !important;
  box-shadow: $button-shadow !important;
  background-color: $button-color !important;
}

.sd-element--with-frame {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-radius: $border-radius !important;
  border: $border !important;
  box-shadow: $shadow !important;
}

.sd-element--complex.sd-element--with-frame > .sd-element__header {
  left: 0;
  width: 100%;
  position: relative;
  background-color: $tertiary-color;
}

.sd-element--complex.sd-element--with-frame > .sd-element__header--location-top:after {
  left: 0;
  width: 100%;
}

.sd-element--complex.sd-element--with-frame > .sd-element__header--location-top:after {
  left: 0;
  width: 0 !important;
}

.sd-table-wrapper:before,
.sd-table-wrapper:after {
  content: unset;
}

.sd-table-wrapper {
  margin: 0 !important;
  width: 100% !important;
  min-width: unset !important;
}

.sd-table-wrapper table tbody tr td {
  background-color: unset !important;
}

.sd-table-wrapper .sd-table__row {
  padding: 20px 20px 20px 20px !important;

  @media screen and (min-width: 600px) {
    padding: unset;
  }
}

.sd-table-wrapper table tbody tr:nth-child(odd) td:first-child {
  width: 100% !important;
  @media screen and (min-width: 680px) {
    width: 31% !important;
  }
}
.sd-table-wrapper table tbody tr:nth-child(odd) {
  td {
    width: 100% !important;

    @media screen and (min-width: 680px) {
      width: 80px !important;
    }
  }
}

.sd-table-wrapper table tbody tr:nth-child(even) {
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease, max-height 0.5s ease;
  @media screen and (min-width: 600px) {
    border-bottom: 10px solid rgb(255, 255, 255);
  }

  td:first-child {
    padding-bottom: 20px;
  }

  td {
    border-bottom: 20px solid rgb(255, 255, 255);
    display: none;
    width: 100% !important;

    @media screen and (min-width: 600px) {
      width: unset !important;
    }
  }

  &.visible {
    max-height: 1000px;
    visibility: visible;
    opacity: 1;

    td {
      display: table-cell;

      .sd-matrix__responsive-title {
        color: $primary-color;
      }

      .sd-radio--checked .sd-radio__decorator {
        background-color: $primary-color !important;

        &:after {
          background-color: $tertiary-color !important;
        }
      }
    }
  }
}

.sd-table-wrapper table tbody tr:nth-child(odd) {
  .sd-radio--checked .sd-radio__decorator {
    background-color: $primary-color !important;

    &:after {
      background-color: $primary-color !important;
    }
  }
}

.sd-table-wrapper table tbody tr:nth-child(even) .sd-radio .sd-radio__decorator {
  width: $survey-checkbox-size-mobile !important;
  height: $survey-checkbox-size-mobile !important;

  @media screen and (min-width: 600px) {
    width: $survey-checkbox-size !important;
    height: $survey-checkbox-size !important;
  }

  &:after {
    width: $survey-checkbox-inner-size-mobile !important;
    height: $survey-checkbox-inner-size-mobile !important;

    @media screen and (min-width: 600px) {
      width: $survey-checkbox-inner-size !important;
      height: $survey-checkbox-inner-size !important;
    }
  }
}

.sd-table-wrapper table tbody tr:nth-child(odd) .sd-radio .sd-radio__decorator {
  border-radius: 0 !important;
  width: $survey-checkbox-size-mobile !important;
  height: $survey-checkbox-size-mobile !important;

  @media screen and (min-width: 600px) {
    width: $survey-checkbox-size !important;
    height: $survey-checkbox-size !important;
  }

  &:after {
    border-radius: 0;
    width: $survey-checkbox-inner-size-mobile !important;
    height: $survey-checkbox-inner-size-mobile !important;

    @media screen and (min-width: 600px) {
      width: $survey-checkbox-inner-size !important;
      height: $survey-checkbox-inner-size !important;
    }
  }

  //box-shadow: 0 0 0 2px $tertiary-color !important;
}

.sd-table-wrapper table tbody tr:nth-child(odd) .sd-radio--checked .sd-radio__decorator {
  background-color: $secondary-color !important;
  border: 1px solid $secondary-color !important;
  box-shadow: 0 0 0 2px $secondary-color !important;

  width: $survey-checkbox-size-mobile !important;
  height: $survey-checkbox-size-mobile !important;

  @media screen and (min-width: 600px) {
    width: $survey-checkbox-size !important;
    height: $survey-checkbox-size !important;
  }
}

.sd-item__control:focus + .sd-item__decorator {
  box-shadow: unset !important;
  background: $primary-color !important;
}

.sd-table-wrapper table tbody tr:nth-child(odd) .validation-error-checked .sd-item__decorator {
  box-shadow: 0 0 0 2px $error-color !important;
  background: $error-background-color !important;
}

.sd-table-wrapper table tbody tr:nth-child(even) .validation-error-checked .sd-item__decorator {
  box-shadow: 0 0 0 2px $error-color !important;
  background: $error-background-color !important;
}

.sd-item--error .sd-item__decorator {
  background: $primary-color !important;
}

.sd-table__cell--header .sv-string-viewer {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased !important;
}

.sd-table__cell--header,
.sd-table__cell {
  width: 100% !important;

  @media screen and (min-width: 600px) {
    min-width: 80px !important;
    width: 80px !important;
  }
}

.sd-table-wrapper table tbody tr:nth-child(even) .sd-table__cell--row-text {
  span {
    float: left;
    margin-bottom: 20px;

    @media screen and (min-width: 600px) {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      position: absolute;
      left: -9px;
      top: -3px;
      width: 60px;
      height: 60px;
      filter: invert(100%) sepia(0%) saturate(7491%) hue-rotate(12deg) brightness(105%) contrast(102%);
      background-image: $survey-question-icon-2;
      background-repeat: no-repeat;

      @media screen and (min-width: 600px) {
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.sd-table-wrapper table tbody tr:nth-child(odd) .sd-table__cell--row-text {
  margin-bottom: 20px;

  @media screen and (min-width: 600px) {
    margin-bottom: 0;
  }

  span {
    float: left;
    margin-bottom: 4px;
    padding: 0;
    min-height: 50px;

    @media screen and (min-width: 600px) {
      margin-bottom: 0;
      padding: 10px 0;
    }

    &:before {
      content: '';
      position: absolute;
      left: -9px;
      top: -3px;

      width: 60px;
      height: 60px;
      background-image: $survey-question-icon-1;
      background-repeat: no-repeat;

      @media screen and (min-width: 600px) {
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.sd-table__cell,
.sd-table__cell--row-text {
  padding: 0 !important;
  line-height: 20px !important;
}

.sd-table-wrapper table tbody tr .sd-table__cell {
  height: unset;

  @media screen and (min-width: 600px) {
    height: 100px !important;
  }
}

.sd-table-wrapper table tbody tr .sd-table__cell--row-text span {
  font-family: $font-family-medium;
  display: block;
  margin-left: 60px;
  min-width: fit-content;
  padding-right: 14px;

  @media screen and (min-width: 600px) {
    min-width: 60px;
    font-family: $font-family;
    margin-left: 77px;
  }
}

.sd-table-wrapper table tbody tr:nth-child(even) .sd-table__cell--row-text {
  margin-bottom: 10px;

  @media screen and (min-width: 600px) {
    margin-bottom: 0;
  }

  span {
    color: $primary-color;
  }
}

.sd-table-wrapper table tbody tr:nth-child(4n),
table tbody tr:nth-child(4n-1) {
  background-color: $primary-color;

  .sd-radio__decorator {
    background-color: #f5f5f5 !important;
  }
}

.sd-table-wrapper table tbody tr:nth-child(4n-2),
table tbody tr:nth-child(4n-3) {
  background-color: #f2f2f2;
}

.sd-table-wrapper table tbody tr:nth-child(4n),
table tbody tr:nth-child(4n) {
  background-color: $tertiary-color;
}

.sd-table-wrapper table tbody tr:nth-child(4n-2),
table tbody tr:nth-child(4n) {
  background-color: $tertiary-color;
}

.sd-element__title span {
  font-size: 18px !important;

  @media screen and (min-width: 600px) {
    font-size: 22px !important;
  }
}

.sd-title.sd-element__title {
  padding-left: 35px !important;
  color: #fff !important;

  @media screen and (min-width: 600px) {
    padding-left: 40px !important;
  }
}

.sd-navigation__prev-btn,
.sd-navigation__next-btn {
  color: $button-text-color !important;
  text-transform: uppercase;
  padding: $button-padding !important;
  border: unset;
  transition: 0.3s;

  &:enabled {
    border: 1px solid $button-border-color;
  }

  &:hover {
    color: $button-hover-text-color !important;
    background-color: $button-hover-color !important;
  }
}

.sd-navigation__complete-btn {
  color: $primary-color !important;
  background-color: $secondary-color !important;
  text-transform: uppercase;
  padding: $button-padding !important;
  border: 1px solid $button-border-color !important;

  &:hover {
    color: $button-hover-text-color !important;
  }
}

.sd-navigation__complete-btn:hover {
  transition: 0.3s;
  color: $secondary-color !important;
  background-color: $primary-color !important;
}

.sd-footer-description {
  width: 100% !important;
  text-align: center !important;

  &.hide {
    display: none !important;
  }

  @media screen and (min-width: 600px) {
    width: fit-content !important;
    text-align: left !important;
  }
}

.sd-element--with-frame > .sd-element__erbox {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
  position: fixed !important;
  left: 0 !important;
  bottom: 0 !important;
  z-index: 1000 !important;
  background: $secondary-color !important;
  color: $primary-color !important;
  font-size: $font-size !important;
  font-family: $font-family !important;
  font-weight: normal !important;
  border-radius: 0 !important;
  padding: 24px;

  @media screen and (min-width: 600px) {
    position: relative !important;
    left: 0 !important;
    bottom: 0 !important;
    padding: 20px 40px;
  }
}

.sd-html {
  h1 {
    margin: 0;
  }

  .html-content {
    display: flex;
    flex-flow: column wrap;

    .brand {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0px 0;

      img {
        width: 200px;

        @media screen and (min-width: 600px) {
          width: 290px;
        }

        @media screen and (min-width: 1024px) {
          width: 360px;
        }
      }
    }

    .content {
      display: flex;
      flex-flow: column wrap;

      @media screen and (min-width: 1024px) {
        flex-flow: column wrap;
      }

      .video-content {
        width: 100%;
        height: auto;

        video {
          width: 100%;
          height: auto;
          border: 1px solid black;
        }
      }

      .description-content {
        margin-top: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-flow: column-reverse;

        @media screen and (min-width: 600px) {
          margin-top: 30px;
          width: 100%;
          flex-direction: row;
        }

        .description {
          flex: 1;
        }

        .navigation {
          flex: 1;
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          margin-top: 20px;
          margin-bottom: 20px;

          .button-container {
            width: 100%;

            @media screen and (min-width: 600px) {
              width: fit-content;
            }

            button {
              width: 100%;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.hidden {
  display: none;
}

.hidden-right {
  animation-name: slideOutRight;
  animation-duration: 300ms;
  animation-timing-function: ease;
}

.visible-right {
  animation-name: slideInRight;
  animation-duration: 300ms;
  animation-timing-function: ease;
}

.hidden-left {
  animation-name: slideOutLeft;
  animation-duration: 300ms;
  animation-timing-function: ease;
}

.visible-left {
  animation-name: slideInLeft;
  animation-duration: 300ms;
  animation-timing-function: ease;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@-webkit-keyframes slideInRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-200%);
  }
}

@-webkit-keyframes slideOutRight {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-200%);
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-200%);
  }

  to {
    transform: translateX(0);
  }
}

@-webkit-keyframes slideInLeft {
  from {
    transform: translateX(-200%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(200%);
  }
}

@-webkit-keyframes slideOutLeft {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(200%);
  }
}
