@import '../../scss/_global.scss';

.login {
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  color: $secondary-color;

  .container {
    padding: 10px;

    .inner-container {
      text-align: center;
      background-color: $background-color;
      padding: 20px;
      box-shadow: $shadow;
      max-width: 400px;
      height: auto;
      max-height: 800px;
      transition: height 1.3s ease-in-out;
      border-radius: $border-radius !important;
      border: $border;

      img {
        width: 200px;
      }

      form {
        margin-top: 20px;

        p {
          margin: 0;
          line-height: 22px;

          &.title {
            font-family: $font-family-medium;
          }
        }
      }

      @media screen and (min-width: 600px) {
        padding: 40px;
      }

      h2 {
        margin-top: 5px;
      }

      label {
        display: block;
        margin-bottom: 10px;
      }

      .code {
        input {
          border: none;
          padding: 10px;
          font-size: $font-size;
          width: 14px;
          margin-top: 20px;
          margin-left: 5px;
          margin-right: 5px;
          text-align: center;
          background-color: $login-code-background-color;
          height: 30px;
          color: $secondary-color;
          border-radius: $border-radius !important;

          @media screen and (min-width: 600px) {
            padding: 16px;
            font-size: $font-size-code;
            width: 18px;
            height: 30px;
          }
        }
      }

      .error {
        color: $secondary-color;
        font-size: $font-size-error;
        margin-top: 20px;
      }

      .department-container {
        margin-top: 20px;

        select {
          display: inline-block;
          font: inherit;
          text-align: start;
          padding: 20px 20px;
          font-size: $font-size;
          border: none;
          margin: 20px 0 0 0;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          -webkit-appearance: none;
          -moz-appearance: none;
          width: 240px;
          color: $secondary-color;
          background-color: $login-select-background-color;

          background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
          background-position: calc(100% - 20px) calc(1em + 12px), calc(100% - 15px) calc(1em + 12px), calc(100% - 2.5em) 0.35em;
          background-size: 5px 5px, 5px 5px, 1px 3em;
          background-repeat: no-repeat;

          &:focus {
            outline: none;
          }
        }
      }

      button {
        padding: 10px;
        border: unset;
        border-radius: $button-border-radius;
        padding: $button-padding;
        margin-top: 30px;
        margin-bottom: 20px;
        font-size: $font-size;
        background-color: $button-color;
        color: $button-text-color;
        box-shadow: $button-shadow;
        transition: background-color 0.3s, color 0.3s, border 0.3s;
        cursor: pointer;

        &:enabled {
          border: 1px solid $button-border-color;
        }

        &:hover {
          background-color: $button-hover-color;
          color: $button-hover-text-color;
        }

        &:disabled,
        &[disabled] {
          background-color: $button-color;
          color: $button-text-color;
          opacity: 0.5;
          cursor: auto;
        }
      }
    }
  }
}
