.footer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
  text-align: center;

  width: 100%;
  margin-top: auto;

  p {
    width: fit-content;
  }
}
